import { transition, trigger, useAnimation } from "@angular/animations";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject, map, Subscription } from "rxjs";
import { LocalizedStateNameService } from "src/app/core/app-services/localized-state-name.service";
import { PermissionsService } from "src/app/core/app-services/permissions.service";
import { ChargingStation } from "src/app/core/data-backend/models";
import { inAnimation } from "src/app/core/helpers/animations";
import { appRepository } from "src/app/core/stores/app.repository";
import {
    AutomaticRecovery,
    createTicketStoreProps,
    detailsRepository,
} from "src/app/core/stores/details.repository";
import { overviewRepository } from "src/app/core/stores/overview.repository";
import { StationsMapSettings } from "src/app/shared/stations-map/stations-map.component";
import { environment } from "src/environments/environment";

export type DetailsHeaderDisplayInfo = {
    stationId: string,
    chargerModel: string,
    chargerVendor: string,
    address: string,
    postalCode: string,
    city: string,
    countryCode: string,
    ocppVersion: string,
    stateClassLastHeartbeatState: string,
    stateClassLastChargingModelState: string,
    stateClassLastErrorState: string,
    overallState: string,
    healthIcon: string,
    healthValue: number | string,
    healthState: string,
    softwareVersion: string,
    recommendation: string,
    recommendationIcon: string,
    customEvseId: string,
    copyCharger: string,
    copyStation: string,
    copyLocation: string,
    copyVersion: string
}

@Component({
    selector: 'app-details-header',
    template: `
        <evc-collapsible
            *ngIf="this.station"
            [parentClasses]="['details-header-collapsible']"
            [active]="(appRepository.selectedCustomer$ | async)?.identifier == 'demo' && permService.hasPermission('stationDetails.header.expandable')"
            [showChevron]="(appRepository.selectedCustomer$ | async)?.identifier == 'demo' && permService.hasPermission('stationDetails.header.expandable')"
            [collapsed]="(headerCollapsed$ | async) ?? true"
            (collapsedChange)="headerCollapsed$.next($event)"
            [@inAnimation]
        >
            <ng-container body>
                <div class="station-details">
                    <div class="d-flex">
                        <div class="map-tile col-4">
                            <app-stations-map
                                [isVisible]="(headerCollapsed$ | async) == false"
                                [stationLocations]="(overviewRepo.stationLocations$ | async)?.data || null"
                                [isLoading]="(overviewRepo.stationLocations$ | async)?.isLoading ?? false"
                                [interactionMode]="'popup-simple'"
                                [markerMode]="'icon'"
                                [highlightedStation]="station"
                                [mapSettings]="mapSettings"
                                [fitAnimation]="'none'"
                            ></app-stations-map>
                        </div>
                        <div 
                            #panelsContainer
                            class="col-8 tab-cont"
                            *evcHasPermissions="'stationDetails.createTicket'"
                        >
                            <div class="tab-tiles">
                                <div 
                                    class="tab-tile"
                                    [class.active]="(panelShown$ | async) == 'ticket-creation'"
                                    (click)="panelShown$.next('ticket-creation')"
                                >
                                    <div class="material-icon">note_add</div>
                                    {{ 'DETAILS_VIEW.TICKET_PANEL.CREATE_TICKET' | translate }}
                                </div>
                            </div>

                            <div 
                                class="tab-card"
                                [class.active]="(panelShown$ | async) == 'ticket-creation'"
                            >
                                <div 
                                    class="tab-cont"
                                    *ngIf="detailsRepo.createTicket$ | async as ticket"
                                >
                                    <div class="ticket-top">
                                        <div class="auto-rec col-4">
                                            {{ 'DETAILS_VIEW.TICKET_PANEL.AUTOMATIC_RECOVERY.TITLE' | translate }}
                                            <div class="recovery-option">
                                                <label>
                                                    <input 
                                                        #ocppMessageCheck
                                                        type="checkbox" 
                                                        [checked]="ticket.automaticRecovery.includes('Recover on OCPP message')"
                                                        (change)="checkChanged(ocppMessageCheck)"
                                                    >
                                                    {{ 'DETAILS_VIEW.TICKET_PANEL.AUTOMATIC_RECOVERY.ON_OCPP' | translate }}
                                                </label>
                                            </div>
                                            <div class="recovery-option">
                                                <label>
                                                    <input 
                                                        #regularBootCheck
                                                        type="checkbox" 
                                                        [checked]="ticket.automaticRecovery.includes('Recover on regular boot')"
                                                        (change)="checkChanged(regularBootCheck)"
                                                    >
                                                    {{ 'DETAILS_VIEW.TICKET_PANEL.AUTOMATIC_RECOVERY.ON_REGULAR_BOOT' | translate }}
                                                </label>
                                            </div>
                                            <div class="recovery-option">
                                                <label>
                                                    <input 
                                                        #regularSessionCheck
                                                        type="checkbox" 
                                                        [checked]="ticket.automaticRecovery.includes('Recover on regular session')"
                                                        (change)="checkChanged(regularSessionCheck)"
                                                    >
                                                    {{ 'DETAILS_VIEW.TICKET_PANEL.AUTOMATIC_RECOVERY.ON_REGULAR_SESSION' | translate }}
                                                </label>
                                            </div>
                                            <div class="recovery-option">
                                                <label>
                                                    <input 
                                                        #neverCheck
                                                        type="checkbox" 
                                                        [checked]="ticket.automaticRecovery.includes('Never Recover')"
                                                        (change)="checkChanged(neverCheck)"
                                                    >
                                                    {{ 'DETAILS_VIEW.TICKET_PANEL.AUTOMATIC_RECOVERY.NEVER_RECOVER' | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="aff-conn col-4">
                                            {{ 'DETAILS_VIEW.TICKET_PANEL.AFFECTED_CONNECTORS' | translate }}:
                                            <div class="connector-selection">
                                                <button 
                                                    class="btn-select-station active"
                                                    [class.active]="ticket.affectedConnectors == 'Station'"
                                                    (click)="selectOption('Station')"
                                                ></button>
                                                <div 
                                                    *ngFor="let connector of station.connectors; let connectorIndex = index"
                                                    (click)="selectOption(connector.connectorId)"
                                                    class="select-connector-container"
                                                >
                                                    <button 
                                                        class="btn-select-connector"
                                                        [class.active]="
                                                            ticket.affectedConnectors != 'Station' &&
                                                            ticket.affectedConnectors.includes(connector.connectorId)
                                                        "
                                                    ></button>
                                                    <div 
                                                        class="connector-index"
                                                        [class.active]="
                                                            ticket.affectedConnectors != 'Station' &&
                                                            ticket.affectedConnectors.includes(connector.connectorId)
                                                        "
                                                    >
                                                        {{ connectorIndex + 1 }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="start-def col-4">
                                            {{ 'DETAILS_VIEW.TICKET_PANEL.START_OF_DEFECT' | translate }}:
                                            <div class="date-picker-cont">
                                                <nz-date-picker
                                                    [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                                    [nzFormat]="'dd.MM.yyyy HH:mm'"
                                                    [(ngModel)]="startOfDefect"
                                                    (ngModelChange)="changeStartOfDefect($event)"
                                                ></nz-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="add-desc pt-8">
                                        {{ 'DETAILS_VIEW.TICKET_PANEL.ADD_DESC' | translate }}:
                                        <textarea
                                            [placeholder]="'DETAILS_VIEW.TICKET_PANEL.DESC_PLACEHOLDER' | translate"
                                            rows="3"
                                            (blur)="updateDesc($event)"
                                        >{{ ticket.description }}</textarea>
                                    </div>
                                    <div 
                                        class="submit-btn"
                                        [tooltip]="'This functionality is not yet ready'"
                                        [size]="'small'"

                                    >
                                        <div class="material-icon">build_circle</div>
                                        {{ 'DETAILS_VIEW.TICKET_PANEL.CREATE_TICKET' | translate }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div 
                        class="close"
                        (click)="headerCollapsed$.next(true)"
                    >
                        <div class="material-icon">expand_less</div>
                        {{ 'COMMON.CLOSE' | translate }}
                    </div>
                </div>
            </ng-container>
        </evc-collapsible>
        <div 
            *ngIf="this.displayInfo as d"
            class="position-absolute station-details-header"
        >
            <div class="container">
                <div class="position-relative station-details-cont">
                    <div class="info-cont">
                        <div [copy-clipboard]="d.copyCharger">
                            <div class="info-box">
                                <div class="info-title">{{ d.chargerModel }}</div>
                                <div class="info-sub">{{ d.chargerVendor }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="v-divider"></div>
                    <div class="info-cont">
                        <div [copy-clipboard]="d.copyStation">
                            <div class="info-box">
                                <div class="info-title">{{ d.stationId }}</div>
                                <div class="info-sub">{{ d.customEvseId }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="v-divider"></div>
                    <div class="info-cont">
                        <div [copy-clipboard]="d.copyLocation">
                            <div class="info-box">
                                <div class="info-title">
                                    {{ d.address }}
                                </div>
                                <div class="info-sub">
                                    {{ d.postalCode }}, {{ d.city }}, {{ d.countryCode }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *evcHasPermissions="'stationDetails.header.states'">
                        <ng-container *evcHasPermissions="'lastHealthIndexValue'">
                            <div class="v-divider"></div>
                            <div class="info-cont {{ d.healthState }}">
                                <div class="material-icon">
                                    {{ d.healthIcon }}
                                </div> 
                                <div class="info-title">
                                    {{ d.healthValue }} %
                                </div>
                            </div>
                        </ng-container>
                        <div class="v-divider"></div>
                        <div class="info-cont">
                            <div class="state-cont">
                                <div class="state-row">
                                    <div 
                                        *evcHasPermissions="'lastHeartbeatState'"
                                        class="material-icon {{ d.stateClassLastHeartbeatState }}"
                                    >
                                        {{ d.stateClassLastHeartbeatState == 'state-failure-txt' ? 'heart_broken' : 'favorite' }}
                                    </div> 
                                    <div
                                        *evcHasPermissions="'lastChargingModelState'" 
                                        class="material-icon {{ d.stateClassLastChargingModelState }}"
                                    >
                                        ev_station
                                    </div> 
                                    <div
                                        *evcHasPermissions="'lastErrorState'"  
                                        class="material-icon {{ d.stateClassLastErrorState }}"
                                    >
                                        warning
                                    </div>
                                </div>
                                <div *evcHasPermissions="'lastOverallState'" class="text-center">
                                    <span 
                                        class="state-bubble text-center"
                                        [class]="d.overallState | stateClass: 'bg'"
                                    >
                                        {{ d.overallState | localizedStateName | async }}
                                    </span>
                                </div> 
                            </div>
                        </div>
                    </ng-container>
                    <div class="v-divider"></div>
                    <div class="info-cont">
                        <div [copy-clipboard]="d.copyVersion">
                            <div class="info-box">
                                <div class="info-title">OCPP: {{ d.ocppVersion }}</div>
                                <div class="info-sub">FW: {{ d.softwareVersion }}</div>
                            </div>
                        </div>
                    </div>
                    <ng-container *evcHasPermissions="'stationDetails.header.recommendations'">
                        <ng-container *ngIf="(appRepository.selectedCustomer$ | async)?.identifier == 'demo'">
                            <div class="v-divider"></div>
                            <div class="info-cont">
                                <div class="material-icon rec-icon">
                                    {{ d.recommendationIcon }}
                                </div>
                                <div>
                                    <div class="info-title">{{ d.recommendation }}</div>
                                    <div class="info-sub">
                                        {{ 'DETAILS_VIEW.TICKET_PANEL.RECOMMENDATION' | translate: {brandName: environment.brandName} }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./details-header.component.scss'],
    animations: [
        trigger('inAnimation', [
            transition(':enter', [
                useAnimation(inAnimation)
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
      '[class.position-relative]': 'true',
      '[class.d-block]': 'true'
    }
})
export class DetailsHeaderComponent implements OnInit, OnDestroy {

    station: ChargingStation | undefined;
    stationSubscription: Subscription | undefined;
    headerCollapsed$ = new BehaviorSubject<boolean>(true);
    @Input() displayInfo: DetailsHeaderDisplayInfo | null = null;

    panelShown$ = new BehaviorSubject<string>('ticket-creation');
    @ViewChild('panelsContainer') panelsContainer!: ElementRef;

    affectedConnectors: number[] | 'Station' = 'Station';
    startOfDefect: Date = new Date();
    createTicketSubscription: Subscription | undefined;
    @ViewChild('ocppMessageCheck') ocppMessageCheck!: ElementRef;
    @ViewChild('regularBootCheck') regularBootCheck!: ElementRef;
    @ViewChild('regularSessionCheck') regularSessionCheck!: ElementRef;
    @ViewChild('neverCheck') neverCheck!: ElementRef;
    public mapSettings: StationsMapSettings = {
        padding: {
            top: 0.01,
            right: 0.01,
            bottom: 0.01,
            left: 0.01
        },
        hideIconsHeight: 20,
        logoPosition: 'bottom-left',
        fitBoundsOnce: false
    };

    readonly environment = environment;

    constructor(
        public overviewRepo: overviewRepository,
        public detailsRepo: detailsRepository,
        public permService: PermissionsService,
        public appRepository: appRepository,
        private _localizedStateService: LocalizedStateNameService
    ) { }

    ngOnInit() {
        // get current station
        this.stationSubscription = this.detailsRepo.station$.subscribe(
            (station: ChargingStation | null) => {
                if (station === null) return;
                this.station = station;

                // get create ticket information
                this.createTicketSubscription = this.detailsRepo.createTicket$.pipe(
                    map((createTicket: createTicketStoreProps) => {
                        if (!this.station) return
                        // clear ticket information if station changed
                        // (only one stations ticket information is cached)
                        if (this.station.stationId != createTicket.stationId) {
                            this.detailsRepo.resetCreateTicket(this.station.stationId);
                        } else {
                            this.startOfDefect = createTicket.startOfDefect;
                            this.affectedConnectors = createTicket.affectedConnectors;
                        }
                    })
                ).subscribe();
            }
        );
    }

    // - - - - - - TICKET CREATION PANEL - - - - - - //

    checkChanged(checkboxChanged: HTMLInputElement) {
        if (!this.station) return
        const checkboxes: {checkbox: ElementRef, label: AutomaticRecovery}[] = [
            {checkbox: this.neverCheck, label: 'Never Recover'},
            {checkbox: this.ocppMessageCheck, label: 'Recover on OCPP message'},
            {checkbox: this.regularBootCheck, label: 'Recover on regular boot'},
            {checkbox: this.regularSessionCheck, label: 'Recover on regular session'}
        ];
        // determine changed checkbox
        const changedCheckbox = checkboxes.find(item => item.checkbox.nativeElement === checkboxChanged);
    
        // if "Never Recover" is checked, uncheck all others
        if (changedCheckbox === checkboxes[0]) {
            checkboxes.forEach(item => item.checkbox.nativeElement.checked = item === changedCheckbox);
        } else if (changedCheckbox) {
            // if a different box is checked - uncheck "Never Recover"
            checkboxes[0].checkbox.nativeElement.checked = false;
        }
    
        // check "Never Recover" if all checkboxes are unchecked
        const anyCheckboxChecked = checkboxes.slice(1).some(item => item.checkbox.nativeElement.checked);
        checkboxes[0].checkbox.nativeElement.checked = !anyCheckboxChecked;
    
        // update the repository with the current checkbox state
        const autoRecs = checkboxes
            .filter(item => item.checkbox.nativeElement.checked)
            .map(item => item.label);
        this.detailsRepo.setCreateTicketAttr('automaticRecovery', autoRecs, this.station.stationId);
    }

    selectOption(option: number | 'Station') {
        if (!this.station) return
        // connector got clicked
        if (option != 'Station') {
            // if old value is 'station' create an empty list
            if (this.affectedConnectors == 'Station') this.affectedConnectors = [];

            // toggle connector id
            const index = this.affectedConnectors.indexOf(option);
            if (index === -1) this.affectedConnectors.push(option);
            else this.affectedConnectors.splice(index, 1);
            
            // if list is empty after toggling set value to 'station'
            if (this.affectedConnectors.length === 0) this.affectedConnectors = 'Station';

        } else {
            // station got clicked
            this.affectedConnectors = 'Station';
        }

        this.detailsRepo.setCreateTicketAttr('affectedConnectors', this.affectedConnectors, this.station.stationId);
    }

    changeStartOfDefect(result: Date) {
        if (!this.station) return
        this.detailsRepo.setCreateTicketAttr('startOfDefect', result, this.station.stationId);
    }

    updateDesc(event: any) {
        if (!this.station) return
        this.detailsRepo.setCreateTicketAttr('description', event.target.value, this.station.stationId);
    }

    ngOnDestroy(): void {
        this.stationSubscription?.unsubscribe();
        this.createTicketSubscription?.unsubscribe();
    }

}
